import React, { Fragment, useState, useEffect } from "react";
import Head from "next/head";
import App from "next/app";
import withReduxStore from "../with-redux-store";
import { Provider } from "react-redux";
import Navbar from "../components/Navbar";
import TopLink from "../components/TopLink";
// import "../Styles/Sass/main.scss";
import "../Styles/Sass/main.scss";
import Loader from "../helpers/Loader";
import Toaster from "../components/reusable/Toaster";
import DownloadFile from "../components/DownloadFile";
import * as Sentry from "@sentry/node";

import { initGA, logPageView } from "../helpers/analytics";
import LogRocket from "../components/LogRocket"

Sentry.init({
	enabled: true,
	dsn: process.env.SENTRY_DSN,
	environment: process.env.NODE_ENV,
	integrations: process.browser
		? [
				new Sentry.Integrations.Breadcrumbs({
					beacon: true,
					console: true,
					dom: true,
					fetch: true,
					history: true,
					sentry: true,
					xhr: true,
					axios: true
				})
		  ]
		: false
});

const MyApp = props => {
	const [loaderStatus, setLoaderStatus] = useState(true);
	//This disables the ability to perform automatic static optimization, causing every page in your app to be server-side rendered.

	useEffect(() => {
		if (!window.GA_INITIALIZED) {
			initGA();
			window.GA_INITIALIZED = true;
		}
		logPageView();
		setLoaderStatus(false);
	});

	const { Component, pageProps, reduxStore } = props;

	if (loaderStatus) <Loader />;

	const MainLayout = ({ children }) => (
		<Fragment>
			<div>
				<TopLink />
				<Navbar />
				<DownloadFile />
			</div>
			{children}
		</Fragment>
	);

	const Layout = Component.CustomLayout || MainLayout;

	return (
		<Provider store={reduxStore}>
			<Fragment>
				<Head>
					<title>Azuga Works</title>
					<link rel="shortcut icon" href="/favicon.ico" />
					<script src="https://fattjs.fattpay.com/js/fattmerchant.js"></script>
				</Head>
				<LogRocket />
				<Layout>
					<Component {...pageProps} />
				</Layout>
			</Fragment>
			<Toaster />
			<div id="AzugaPortal" />
		</Provider>
	);
};

MyApp.getInitialProps = async appContext => {
	//Below condition is a workaround for trailing slash issue in Nextjs
	//https://github.com/zeit/next.js/issues/5214
	if (
		appContext &&
		appContext.ctx &&
		appContext.ctx.req &&
		(appContext.ctx.req.url.match(/[/]/g) || []).length > 1
	) {
		const pathAndQueryDivided = appContext.ctx.req.url.split("?");
		if (pathAndQueryDivided[0].endsWith("/")) {
			const urlWithoutEndingSlash = pathAndQueryDivided[0].replace(
				/\/*$/gim,
				""
			);

			appContext.ctx.res.writeHead(301, {
				Location:
					urlWithoutEndingSlash +
					(pathAndQueryDivided.length > 1 ? `?${pathAndQueryDivided[1]}` : "")
			});
			appContext.ctx.res.end();
		}
	}

	// calls page's `getInitialProps` and fills `appProps.pageProps`
	const appProps = await App.getInitialProps(appContext);

	return { ...appProps };
};

export default withReduxStore(MyApp);
