//This is a hack to share variables between SASS and JS Files
//Make sure both the files have the same code
//Path to the code of SASS Variable defination is "../Styles/Sass/utils/theme.scss"

module.exports = {
	az_red: "#be3131",
	az_maroon: "#982323",
	az_yellow: "#d29f13",
	az_gold: "#b78b20",
	az_green: "#6ca639",
	az_blue: "#00a3e0",
	az_cyan: "#0069b1",
	az_light_blue: "#D9E9F3",
	az_faded_blue: "#7FA5C3",
	az_navy: "#004b87",
	az_gray_1: "#f6f6f6",
	az_gray_2: "#eeeeee",
	az_gray_3: "#d8d8d8",
	az_gray_4: "#979797",
	az_gray_5: "#75777d",
	az_gray_6: "#343741",
	az_white: "#ffffff",
	az_white_transparent: "rgba(white, 0.1)",
	az_black: "#040000",
	az_black_transparent: "rgba(black, 0.1)",
	az_border_color: "#eeeeee",

	//Reports Pie Chart Color
	az_rpt_green: "#20EE34",
	az_rpt_yellow: "#FAC228",
	az_rpt_blue: "#1FC0FC",
	az_rpt_gray: "#C8C8C8",
	az_rpt_label_blue: "#0667B7",
	az_rpt_red: "#F25252",
	az_rpt_black: "#2E2E2E",
	az_rpt_table_text: "#333333",

	//Schedule new Status Color
	az_open: "#71C0D9",
	az_in_progress: "#66F29E",
	az_hold: "#435159",
	az_closed: "#BB604C"
};
