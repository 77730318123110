import React from "react";
import Link from "next/link";
import _ from "lodash";
import { useSelector } from "react-redux";
import { GiHamburgerMenu } from "react-icons/gi";
import { useRouter } from "next/router";

import ThemeColor from "../config/theme";
import { List, ListItem } from "../components/reusable/List";
import "@reach/menu-button/styles.css";
import {
	MenuButton,
	MenuList,
	MenuItem,
	Menu,
	MenuPopover
} from "@reach/menu-button";

const Navbar = () => {
	const userPreferences = useSelector(state => state.UserPreferences);
	const userData = useSelector(state => state.userData.user);
	const { userType, privileges } = userData;
	const adminTypes = [1, 2];

	if (_.isEmpty(userPreferences) || _.isEmpty(userData)) return null;

	const preferredJobLabel = userPreferences.find(
		preference => preference.preferenceKey === "JOB_LABEL"
	);

	const router = useRouter();

	const isCRM =
		adminTypes.includes(userType) ||
		privileges.includes("CUSTOMER_VIEW") ||
		privileges.includes("ESTIMATE_VIEW");

	const isAccounting =
		adminTypes.includes(userType) || privileges.includes("INVOICE_VIEW");

	const isWorkForce =
		adminTypes.includes(userType) ||
		privileges.includes("TIME_MANAGEMENT_MANAGE") ||
		privileges.includes("OUT_OF_OFFICE_VIEW");

	const catalogAccess =
		userPreferences.find(o => o.preferenceKey === "UTILIZE_CATALOG").value ===
		"Yes";

	const NavMenu = ({ title, children, routes = [] }) => (
		<li
			className={`nav-menu ${
				router && routes.includes(router.pathname) && "active"
			}`}>
			<div className="nav-menu-title">{title}</div>

			<ul className="nav-menu-list">{children}</ul>
		</li>
	);

	const NavMenuItem = ({ href, privilege, children }) =>
		(adminTypes.includes(userType) || privileges.includes(privilege)) && (
			<li className={router && router.pathname === href ? "active" : ""}>
				<Link href={href}>
					<a>{children}</a>
				</Link>
			</li>
		);

	return (
		<div id="navbar" style={{ background: ThemeColor.az_gray_6 }}>
			<button className="hamburger-menu" type="button">
				<Menu className="left">
					<MenuButton>
						<GiHamburgerMenu />
					</MenuButton>
					<MenuPopover>
						<MenuList className="navigation-menu-list">
							{(adminTypes.includes(userType) ||
								privileges.includes("CUSTOMER_VIEW")) && (
								<MenuItem
									className={`navigation-menu-item ${
										router && router.pathname === "/" ? "active" : ""
									}`}
									onSelect={() => null}>
									<a href="/">Customers</a>
								</MenuItem>
							)}

							{(adminTypes.includes(userType) ||
								privileges.includes("JOB_VIEW")) && (
								<MenuItem
									className={`navigation-menu-item ${
										router && router.pathname === "/jobs" ? "active" : ""
									}`}
									onSelect={() => null}>
									<a href="/jobs">
										{preferredJobLabel && preferredJobLabel.value}
									</a>
								</MenuItem>
							)}
							{(adminTypes.includes(userType) ||
								privileges.includes("S_D_VIEW")) && (
								<MenuItem
									className={`navigation-menu-item ${
										router && router.pathname === "/schedule-dispatch"
											? "active"
											: ""
									}`}
									onSelect={() => null}>
									<a href="/schedule-dispatch">Scheduling</a>
								</MenuItem>
							)}
							{adminTypes.includes(userType) && (
								<MenuItem
									className={`navigation-menu-item ${
										router && router.pathname === "/workforce" ? "active" : ""
									}`}
									onSelect={() => null}>
									<a href="/workforce">workforce</a>
								</MenuItem>
							)}
							{(adminTypes.includes(userType) ||
								privileges.includes("ESTIMATE_VIEW")) && (
								<MenuItem
									className={`navigation-menu-item ${
										router && router.pathname === "/estimates" ? "active" : ""
									}`}
									onSelect={() => null}>
									<a href="/estimates">Estimates</a>
								</MenuItem>
							)}
							{(adminTypes.includes(userType) ||
								privileges.includes("INVOICE_VIEW")) && (
								<MenuItem
									className={`navigation-menu-item ${
										router && router.pathname === "/invoices" ? "active" : ""
									}`}
									onSelect={() => null}>
									<a href="/invoices">Invoices</a>
								</MenuItem>
							)}
							{(adminTypes.includes(userType) ||
								privileges.includes("REPORT_VIEW")) && (
								<MenuItem
									className={`navigation-menu-item ${
										router && router.pathname === "/reports" ? "active" : ""
									}`}
									onSelect={() => null}>
									<a href="/reports">Reports</a>
								</MenuItem>
							)}
							{(adminTypes.includes(userType) ||
								privileges.includes("ACCOUNT_SETTINGS_VIEW") ||
								privileges.includes("CUSTOM_FIELDS_VIEW") ||
								privileges.includes("CUSTOMER_SETTINGS_VIEW") ||
								privileges.includes("ESTIMATE_SETTINGS_VIEW") ||
								privileges.includes("INVOICE_SETTINGS_VIEW") ||
								privileges.includes("GROUP_SETTINGS_VIEW") ||
								privileges.includes("GROUP_VIEW") ||
								privileges.includes("JOB_SETTINGS_VIEW") ||
								privileges.includes("SERVICE_TYPE_VIEW") ||
								privileges.includes("DATA_MIGRATION_VIEW") ||
								privileges.includes("JOB_STATUS_VIEW") ||
								privileges.includes("WORKER_STATUS_VIEW") ||
								privileges.includes("TAG_VIEW") ||
								privileges.includes("TAX_VIEW") ||
								privileges.includes("WORKFORCE_VIEW") ||
								privileges.includes("ROLE_VIEW") ||
								privileges.includes("TEAM_VIEW") ||
								privileges.includes("USER_VIEW")) && (
								<MenuItem
									className={`navigation-menu-item ${
										router && router.pathname === "/account-settings"
											? "active"
											: ""
									}`}
									onSelect={() => null}>
									<a href="/account-settings">Settings</a>
								</MenuItem>
							)}
						</MenuList>
					</MenuPopover>
				</Menu>
			</button>
			<List verticle className="navigation">
				{isCRM && (
					<NavMenu title="CRM" routes={["/", "/estimates"]}>
						<NavMenuItem privilege="CUSTOMER_VIEW" href="/">
							Customers
						</NavMenuItem>
						<NavMenuItem privilege="ESTIMATE_VIEW" href="/estimates">
							Estimates
						</NavMenuItem>
					</NavMenu>
				)}
				{(adminTypes.includes(userType) || privileges.includes("JOB_VIEW")) && (
					<ListItem href="/jobs">
						{preferredJobLabel && preferredJobLabel.value}
					</ListItem>
				)}
				{(adminTypes.includes(userType) || privileges.includes("S_D_VIEW")) && (
					<ListItem href="/schedule-dispatch">Scheduling</ListItem>
				)}
				{isWorkForce && (
					<NavMenu title="WORKFORCE" routes={["/workforce", "/out-of-office"]}>
						<NavMenuItem privilege="TIME_MANAGEMENT_MANAGE" href="/workforce">
							Time Management
						</NavMenuItem>
						<NavMenuItem privilege="OUT_OF_OFFICE_VIEW" href="/out-of-office">
							Out Of Office
						</NavMenuItem>
					</NavMenu>
				)}
				{isAccounting && (
					<NavMenu title="Accounting" routes={["/invoices"]}>
						<NavMenuItem privilege="INVOICE_VIEW" href="/invoices">
							Invoices
						</NavMenuItem>
					</NavMenu>
				)}
				{catalogAccess && <ListItem href="/catalog">Catalog</ListItem>}
				{(adminTypes.includes(userType) ||
					privileges.includes("REPORT_VIEW")) && (
					<ListItem href="/reports">Reports</ListItem>
				)}
				{(adminTypes.includes(userType) ||
					privileges.includes("ACCOUNT_SETTINGS_VIEW") ||
					privileges.includes("CUSTOM_FIELDS_VIEW") ||
					privileges.includes("CUSTOMER_SETTINGS_VIEW") ||
					privileges.includes("ESTIMATE_SETTINGS_VIEW") ||
					privileges.includes("INVOICE_SETTINGS_VIEW") ||
					privileges.includes("GROUP_SETTINGS_VIEW") ||
					privileges.includes("GROUP_VIEW") ||
					privileges.includes("JOB_SETTINGS_VIEW") ||
					privileges.includes("SERVICE_TYPE_VIEW") ||
					privileges.includes("DATA_MIGRATION_VIEW") ||
					privileges.includes("JOB_STATUS_VIEW") ||
					privileges.includes("WORKER_STATUS_VIEW") ||
					privileges.includes("TAG_VIEW") ||
					privileges.includes("TAX_VIEW") ||
					privileges.includes("WORKFORCE_VIEW") ||
					privileges.includes("ROLE_VIEW") ||
					privileges.includes("TEAM_VIEW") ||
					privileges.includes("USER_VIEW")) && (
					<ListItem href="/account-settings">Settings</ListItem>
				)}
			</List>
		</div>
	);
};

export default Navbar;
