/* eslint-disable no-console */
/* eslint-disable no-shadow */
import React, { useRef, useState, useEffect } from "react";
import {
	MenuButton,
	MenuList,
	MenuItem,
	Menu,
	MenuPopover
} from "@reach/menu-button";
import { FaChevronDown } from "react-icons/fa";
import "@reach/menu-button/styles.css";
import { useSelector } from "react-redux";
import redirectTo from "../helpers/redirectTo";
import { parseCookies, setCookie } from "nookies";
import _ from "lodash";
import { FaCaretDown } from "react-icons/fa";
import { BsArrowRight } from "react-icons/bs";
import { FaSistrix } from "react-icons/fa";
import { useFormik } from "formik";
import axios from "../config/Axios";
import { logError } from "../config/SentryConfig";
import Loader from "../helpers/Loader";

const outsideImpersonationClickHandler = (
	impersonationWindow,
	downCaret,
	setShowUser
) => {
	useEffect(() => {
		const handleClickOutside = event => {
			if (
				impersonationWindow.current &&
				!impersonationWindow.current.contains(event.target) &&
				downCaret.current &&
				!downCaret.current.contains(event.target)
			) {
				setShowUser(false);
			}
		};

		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [impersonationWindow, downCaret]);
};

const TopLink = () => {
	const cookies = parseCookies();
	const userData = useSelector(state => state.userData.user);
	const userName = `${userData.firstName} ${userData.lastName}`;
	const [showUser, setShowUser] = useState(false);
	const [showSwitchToFleet, setSwitchToFleet] = useState(false);
	const [userList, setUserList] = useState([]);
	const [filteredUserList, setFilteredUserList] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [showError, setShowError] = useState(false);
	const [selectedUser, setSelectedUser] = useState(null);

	const refererUrl = cookies.fleetRedirect
		? cookies.fleetRedirect
		: process.env.REDIRECT_URL;

	useEffect(() => {
		if (userData.impersonation) {
			axios
				.get(`/profile/su/users`)
				.then(res => {
					if (res.data.statusCode === 200) {
						if (res.data.data.length) {
							setUserList(res.data.data);
						}
					}
				})
				.catch(err => {
					logError(err, 0);
				});
		}
	}, []);

	useEffect(() => {
		if (cookies.token) {
			axios
				.get(`/profile/user/subscription`)
				.then(res => {
					if (
						res.data.statusCode === 200 &&
						res.data.data.length &&
						res.data.data.includes("FLEET")
					)
						setSwitchToFleet(true);
					else setSwitchToFleet(false);
				})
				.catch(err => {
					logError(err, 0);
				});
		}
	}, []);

	const userProfilePic = "";
	const domain =
	process.env.FSM_DOMAIN === `localhost`
		? process.env.FSM_DOMAIN
		: `.${process.env.FSM_DOMAIN}`;
	const expiredOptions = {
		path: "/",
		maxAge: -1,
		domain
	};
	const redirectUrl = process.env.REDIRECT_URL;
	const tokenList = cookies["token-list"]
		? JSON.parse(cookies["token-list"])
		: [];

	const destroyAllCookies = async () => {
		setCookie(null, "token", "", expiredOptions);
		setCookie(null, "refreshToken", "", expiredOptions);
		setCookie(null, "oauthToken-apikey", "", expiredOptions);
		setCookie(null, "jwt", "", expiredOptions);
		setCookie(null, "token-list", "", expiredOptions);
		setCookie(null, "fleetRedirect", "", expiredOptions);
		setCookie(null, "loggedOut", true,{path:"/"} );
		return true;
	};
	const logOut = async () => {
		await destroyAllCookies()
			.then(() => redirectTo(redirectUrl))
			.catch(error => console.log(error));
	};

	const redirectToNewUser = URL => {
		redirectTo(URL);
	};

	if (_.isEmpty(userData)) return null;

	const toggleShowUser = () => {
		setShowUser(!showUser);
		setShowError(false);
		formik.setFieldValue("userInput", "");
		setSelectedUser(null);
		setFilteredUserList([]);
	};

	const getMatchingUserSuggestions = value => {
		if (value) {
			value = value.replace(/\s/g, "").toLowerCase();
			setFilteredUserList(
				userList.filter(item => {
					const fullName = `${item.firstName}${item.lastName}`;
					return (
						item.userId !== userData.userId &&
						(item.firstName.toLowerCase().indexOf(value) > -1 ||
							item.lastName.toLowerCase().indexOf(value) > -1 ||
							fullName.toLowerCase().indexOf(value) > -1)
					);
				})
			);
		} else {
			setFilteredUserList([]);
		}
	};

	const validateSelectedUser = userInfo => {
		if (userInfo) {
			setSelectedUser(userInfo.attributes[1].value);
		}
	};

	const getToken = selectedUser => {
		setIsLoading(true);
		const userTokenObject = tokenList.find(
			tokenObject => tokenObject.userId === selectedUser
		);
		if (userTokenObject) {
			redirectToNewUser(`?token=${userTokenObject.token}`);
			setIsLoading(false);
		} else {
			const apiPostData = {
				targetUserId: selectedUser
			};
			axios
				.post(`/profile/su/switch-user`, apiPostData)
				.then(res => {
					if (res.data.statusCode === 200) {
						if (res.data.data && res.data.data.accessToken) {
							const tempTokenList = tokenList;
							const tokenObject = {
								userId: selectedUser,
								token: res.data.data.accessToken
							};
							tempTokenList.push(tokenObject);
							setCookie(null, "token-list", JSON.stringify(tempTokenList));
							redirectToNewUser(`?token=${res.data.data.accessToken}`);
						}
					}
					setIsLoading(false);
				})
				.catch(err => {
					setIsLoading(false);
					logError(err, 0);
				});
		}
	};

	const initialFormValues = {
		userInput: ""
	};

	// Formik Hook
	const formik = useFormik({
		initialValues: initialFormValues,
		onSubmit: () => {
			if (!selectedUser) setShowError(true);
			else getToken(selectedUser);
		}
	});

	const impersonationWindow = useRef();
	const downCaret = useRef();

	outsideImpersonationClickHandler(impersonationWindow, downCaret, setShowUser);

	return (
		<div id="toplink">
			<form onSubmit={formik.handleSubmit}>
				<ul className="toplink_container">
					<li className="logo">
						<img src="/azuga_logo.svg" alt="Logo" />
						<span
							style={{
								fontWeight: "bold",
								margin: "0px 25px",
								display: "flex"
							}}>
							CID: {userData.tenantId ? userData.tenantId : ""}
							{userData.impersonation ? (
								<div ref={downCaret}>
									<FaCaretDown
										className="caret-icon"
										onClick={toggleShowUser}
									/>
								</div>
							) : (
								""
							)}
						</span>
						{showSwitchToFleet ? (
							<button
								className="switch"
								onClick={() => (document.location.href = refererUrl)}>
								Switch to Azuga Fleet
								<span>
									<BsArrowRight />
								</span>
							</button>
						) : (
							""
						)}
					</li>
					<li className="group group_center">
						<span className="groupItem ">
							{userProfilePic ? (
								<img src={userProfilePic} alt="Avatar" className="avatar" />
							) : userData.firstName ? (
								<span className="prof_icon avatar">
									{userData.firstName.charAt(0)}
								</span>
							) : (
								<img
									src="/icons/profile_img_placeholder.svg"
									alt="Avatar"
									className="avatar"
								/>
							)}
						</span>
						<span className="groupItem groupItem_accWidth">
							<Menu className="left">
								<MenuButton className="group group_center">
									<span className="groupItem groupItem_accWidth">
										Hi {userName}{" "}
									</span>
									<span className="groupItem groupItem_icon">
										<FaChevronDown />
									</span>
								</MenuButton>
								<MenuPopover>
									<MenuList>
										<MenuItem onSelect={() => logOut()}>Logout</MenuItem>
									</MenuList>
								</MenuPopover>
							</Menu>
						</span>
					</li>
				</ul>
				{showUser ? (
					<div
						class="user-selection-popup"
						id="userSelection"
						ref={impersonationWindow}>
						<span className="user-selection-popup-element customer_name">
							{userData.tenantName}
						</span>
						<span className="user-selection-popup-element">
							Viewing As {userName}
						</span>
						<div className="user-selection-popup-element">
							<p className="search-group">
								<input
									id="userInput"
									name="userInput"
									type="text"
									className="small"
									placeholder="Search User"
									list="users"
									onChange={e => {
										formik.setFieldValue("userInput", e.target.value);
										setShowError(false);
										getMatchingUserSuggestions(e.target.value);
										setSelectedUser(null);
									}}
									onBlur={formik.handleBlur}
									value={formik.values.userInput}
									autoComplete="off"
									onSelect={e =>
										validateSelectedUser(
											// eslint-disable-next-line no-undef
											users.options.namedItem(e.target.value.toString())
										)
									}
								/>
								<FaSistrix className="search_icon" />
							</p>
							<datalist id="users">
								{filteredUserList.map(user => {
									return (
										<option
											value={`${user.firstName} ${user.lastName}`}
											data-id={user.userId}
											id={`${user.firstName} ${user.lastName}`}
										/>
									);
								})}
							</datalist>
							{!isLoading ? (
								<button
									className={
										formik.values.userInput
											? "button switch_btn"
											: "button switch_btn disabled"
									}
									disabled={formik.values.userInput ? false : true}
									type="submit">
									Switch
								</button>
							) : (
								<span className="button switch_btn">
									<Loader inline />
								</span>
							)}
						</div>
						{showError ? (
							<span className="user-selection-popup-element error">
								Please select a user
							</span>
						) : (
							""
						)}
					</div>
				) : (
					""
				)}
			</form>
		</div>
	);
};

export default TopLink;
